
























































































































































.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
/deep/.el-switch__label--left.is-active + .el-switch__core{
  border-color: rgb(255, 73, 73)!important;
  background-color: rgb(255, 73, 73)!important;
}
/deep/.el-switch.is-checked .el-switch__core {
  background-color: #5fb878 !important;
  border-color: #5fb878 !important;
}
/deep/ .el-switch__label--left{
  left: 0!important;
  z-index: 999!important;
}
/deep/ .el-switch__label--right{
  right: 0!important;
  z-index: 999!important;
}
/deep/ .el-switch__label{
  color: #666!important;
}
/deep/ .el-switch__label.is-active {
  color: #409eff!important;
}
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
